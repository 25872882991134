import {
	wayfindingMapStartup,
	renderPath,
	resetMap,
	resetMapIfNoPath,
	getSystemOrPropertyOrAccountSetting,
} from './wayfinding';

import {
	applyFilters,
	buildFilterList,
} from './Filter';

import { broncoURLENV } from './env';

window.listingsData = [];

const dataLayerPush = (payload) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: payload.event,
		label: payload.label,
		scope: payload.scope,
		clickable: payload.clickable,
		opening: payload.opening,
		focus: payload.focus,
		makeCall: payload.makeCall,
	});
};

let systemMap = {};
let componentToContentGroupMap = {};

let listingM1Keys = [];
let listingToTierMap = {};
let listingToParentListingsMap = {};
let listingToListingCollectionsMap = {};
let listingToListingConfigMap = {};
let listingToSystemsMap = {};

let listingCollectionToListingConfigTierMap = {};
let listingCollectionToParentComponentMap = {};
let listingCollectionToSystemsMap = {};

let listingConfigToListingCollectionsMap = {};
let listingConfigToListingsColumnsMap = {};
let listingConfigToListingsMap = {};

const getParentM1Keys = function (fd, childM2Key) {
	let parentM1Keys = [];
	for (let [m1Key, m1Data] of Object.entries(fd)) {
		for (let m2Key of Object.keys(m1Data)) {
			if (m2Key === childM2Key) {
				parentM1Keys.push(m1Key);
			}
		}
	}

	return parentM1Keys;
}

const makeMaps = function () {
	const fd = window.flatData

	for (let [m1Key, m1Data] of Object.entries(fd)) {
		if (m1Key.startsWith('system.')) {
			systemMap[m1Key] = { name: m1Data.display_name };
		}

		if (m1Key.startsWith('listing.')) {
			listingM1Keys.push(m1Key);

			for (let m2Key of Object.keys(m1Data)) {
				if (m2Key.startsWith('listing.')) {
					if (!listingToParentListingsMap[m2Key]) {
						listingToParentListingsMap[m2Key] = [];
					}

					listingToParentListingsMap[m2Key].push(m1Key);
				}
			}
		}

		if (m1Key.startsWith('listing_collection.')) {
			if (!listingCollectionToListingConfigTierMap[m1Key]) {
				listingCollectionToListingConfigTierMap[m1Key] = {};
			}

			for (let m2Key of Object.keys(m1Data)) {
				if (m2Key.startsWith('listing_config.')) {
					let m2Data = fd[m2Key];

					// T1 listing_config
					listingCollectionToListingConfigTierMap[m1Key][0] = m2Key;

					if (!listingConfigToListingCollectionsMap[m2Key]) {
						listingConfigToListingCollectionsMap[m2Key] = [];
					}
					listingConfigToListingCollectionsMap[m2Key].push(m1Key);

					// T(n) listing_config
					let i = 0;
					while (~Object.keys(m2Data).join('').indexOf('listing_config.')) {
						i++;
						let childListingConfigM1Key = Object.keys(m2Data).filter((k) => {
							return k.startsWith('listing_config.');
						})[0];

						m2Data = fd[childListingConfigM1Key];
						listingCollectionToListingConfigTierMap[m1Key][i] = childListingConfigM1Key;

						if (!listingConfigToListingCollectionsMap[childListingConfigM1Key]) {
							listingConfigToListingCollectionsMap[childListingConfigM1Key] = [];
						}
						listingConfigToListingCollectionsMap[childListingConfigM1Key].push(m1Key);
					}
				}
			}
		}

		if (m1Key.startsWith('listing_config.')) {
			if (!listingConfigToListingsColumnsMap[m1Key]) {
				listingConfigToListingsColumnsMap[m1Key] = {};
			}

			for (let [m2Key, m2Data] of Object.entries(m1Data)) {
				if (m2Key.startsWith('listing_column.')) {
					listingConfigToListingsColumnsMap[m1Key][m2Key] = m2Data;
					listingConfigToListingsColumnsMap[m1Key][m2Key].name = fd[m2Key].display_name;

					let listingColumnTypeM1Key = Object.keys(fd[m2Key]).filter((k) => {
						return k.startsWith('listing_column_type.');
					})[0];

					listingConfigToListingsColumnsMap[m1Key][m2Key].type = fd[listingColumnTypeM1Key].type;
					listingConfigToListingsColumnsMap[m1Key][m2Key].fieldType = fd[listingColumnTypeM1Key].display_name;
				}
			}
		}

		if (m1Key.startsWith('component.')) {
			for (let m2Key of Object.keys(m1Data)) {
				if (m2Key.startsWith('listing_collection.')) {
					listingCollectionToParentComponentMap[m2Key] = m1Key;

					let parents = getParentM1Keys(fd, m1Key), skip = false;
					while (!~parents.join('').indexOf('system.')) {
						parents = getParentM1Keys(fd, parents[0]);

						if (parents.length === 0) {
							skip = true;
							break;
						}
					}

					if (skip) {
						console.warn('SKIP?:', m1Key);
						continue;
					}

					let systemM1Keys = parents.filter((k) => {
						return k.startsWith('system.');
					});

					if (!listingCollectionToSystemsMap[m2Key]) {
						listingCollectionToSystemsMap[m2Key] = [];
					}
					for (let systemM1Key of systemM1Keys) {
						listingCollectionToSystemsMap[m2Key].push(systemM1Key);
					}
				}
			}
		}
	}

	for (let [ , componentM1Key] of Object.entries(listingCollectionToParentComponentMap)) {
		for (let bundle of (fd[componentM1Key]?._bundle || [])) {
			if (bundle.attr.type === 'custom_group') {
				if (!componentToContentGroupMap[componentM1Key]) {
					componentToContentGroupMap[componentM1Key] = [];
				}
				componentToContentGroupMap[componentM1Key].push(bundle);

			}
		}
	}

	for (let listingM1Key of listingM1Keys) {
		let parents = getParentM1Keys(fd, listingM1Key);

		let i = 0, skip = false;
		while (!~parents.join('').indexOf('listing_collection.')) {
			i++;
			parents = getParentM1Keys(fd, parents[0]);

			if (parents.length === 0) {
				skip = true;
				break;
			}
		}

		if (skip && fd[listingM1Key]) {
			let cols = Object.keys(fd[listingM1Key])
				.map((k) => {
					if (k.startsWith('listing_column')) {
						let v = fd[listingM1Key][k]
						if (v) {
							return JSON.stringify(v);
						}

						return null;
					}
					return null;
				})
				.filter((col) => {
					return col;
				});
			console.log('ORPHAN:', listingM1Key, cols);
			continue;
		}

		listingToTierMap[listingM1Key] = i;

		if (!listingToListingCollectionsMap[listingM1Key]) {
			listingToListingCollectionsMap[listingM1Key] = [];
		}

		if (!listingToSystemsMap[listingM1Key]) {
			listingToSystemsMap[listingM1Key] = [];
		}

		for (let parentM1Key of parents) {
			if (parentM1Key.startsWith('listing_collection.')) {
				let listingCollectionM1Key = parentM1Key;
				listingToListingCollectionsMap[listingM1Key].push(listingCollectionM1Key);

				let systemM1Keys = listingCollectionToSystemsMap[listingCollectionM1Key] || [];
				for (let systemM1Key of systemM1Keys) {
					listingToSystemsMap[listingM1Key].push(systemM1Key);
				}

				let listingConfigM1Key = listingCollectionToListingConfigTierMap[listingCollectionM1Key][i];
				listingToListingConfigMap[listingM1Key] = listingConfigM1Key;
			}
		}
	}

	for (let [listingM1Key, listingConfigM1Key] of Object.entries(listingToListingConfigMap)) {
		if (listingConfigM1Key === undefined) {
			continue;
		}

		if (!listingConfigToListingsMap[listingConfigM1Key]) {
			listingConfigToListingsMap[listingConfigM1Key] = {};
		}

		let listing = {
			vals: {},
			systems: listingToSystemsMap[listingM1Key],
			tier: listingToTierMap[listingM1Key],
		};
		if (listingToParentListingsMap[listingM1Key]) {
			listing.parent_listings = listingToParentListingsMap[listingM1Key];
		}

		let cols = listingConfigToListingsColumnsMap[listingConfigM1Key];
		listing.cols = cols;

		for (let [colM1Key, col] of Object.entries(cols)) {
			if (typeof fd?.[listingM1Key]?.[colM1Key] === 'undefined') {
				listing.vals[col.name] = null;
			} else {
				let type = col.type;

				listing.vals[col.name] = fd[listingM1Key][colM1Key][type];
			}
		}

		let listingCollectionM1Keys = listingToListingCollectionsMap[listingM1Key];
		for (let listingCollectionM1Key of listingCollectionM1Keys) {
			let componentM1Key = listingCollectionToParentComponentMap[listingCollectionM1Key];

			if (typeof fd[componentM1Key]?._bundle !== 'undefined') {
				if (!listing.content_groups) {
					listing.content_groups = [];
				}

				listing.content_groups.push(componentM1Key);
			}
		}

		listingConfigToListingsMap[listingConfigM1Key][listingM1Key] = listing;
	}

	let listings = {
		systems: systemMap,
		content_groups: componentToContentGroupMap,
		listings: {},
		cols: {},
	};

	for (let listingConfigM1Key of Object.keys(listingConfigToListingsMap)) {
		listings.cols = { ...(listings.cols || {}), ...listingConfigToListingsColumnsMap[listingConfigM1Key] };
		listings.listings[listingConfigM1Key] = listingConfigToListingsMap[listingConfigM1Key];
	}

	console.log(listings);
}

// ========== ETL Layer ==========
let listingCollectionComponentToListingCollectionMap = {};

const tmpListingETL = function () {
	const typedComponents = {
		'listing_collection': {},
	};

	for (let [m1Key, m1Data] of Object.entries(window.flatData)) {
		if (m1Key.startsWith('component.')) {
			for (let m2Key of Object.keys(m1Data)) {
				if (m2Key.startsWith('listing_collection.')) {
					typedComponents['listing_collection'][m1Key] = {};

					listingCollectionComponentToListingCollectionMap[m1Key] = m2Key;
				}
			}
		}
	}

	const getComponentSettings = function (m1Key) {
		let m1Data = window.flatData[m1Key];

		let settings = {};
		for (let [m2Key, m2Data] of Object.entries(m1Data)) {
			if (m2Key.startsWith('setting.')) {
				let setting = window.flatData[m2Key];
				for (let key of Object.keys(setting)) {
					if (key.startsWith('setting_type.')) {
						let settingType = window.flatData[key].type;
						let settingValue = m2Data[settingType] || null;
						try {
							if (settingType === 'json') {
								settingValue = JSON.parse(m2Data[settingType]);
							}
						} catch (err) { }

						settings[setting.name] = {
							type: settingType,
							value: settingValue,
						};

						break;
					}
				}
			}
		}

		return settings;
	}

	const getListing = function (m1Key, listingGroup, listingGroupM1Key, listingConfigTierlist) {
		let listings = [];
		let rawCols = {};

		for (let [m2Key, m2Data] of Object.entries(window.flatData[m1Key])) {
			if (m2Key.startsWith('listing.')) {
				let listing = getListing(m2Key, listingGroup, listingGroupM1Key, listingConfigTierlist);
				listing.m1Key = m2Key;
				listing.settings = getComponentSettings(listing.m1Key);

				listings.push(listing);
			}

			if (m2Key.startsWith('listing_column.')) {
				let keys = Object.keys(m2Data);
				rawCols[m2Key] = m2Data[keys[0]];
			}
		}

		//get listing parents,
		let rootParent = false
		let parentM1Keys = [];
		let currentChildKey = m1Key
		while (!rootParent) {
			let parentKey = listingToParentListingsMap[currentChildKey] || ''
			if (!parentKey) {
				rootParent = true
				break;
			}

			parentKey = [...new Set(parentKey)];
			parentM1Keys.push(parentKey)
			currentChildKey = parentKey						
		}

		//get parent cols		
		let parentCols = {}
		parentM1Keys.forEach(function (parentM1Key) {
			if (parentM1Key.length === 1) {
				for (let [m2Key, m2Data] of Object.entries(window.flatData[parentM1Key])) {

					if (m2Key.startsWith('listing_column.')) {
						let keys = Object.keys(m2Data);
						parentCols[m2Key] = m2Data[keys[0]]; 
					}
				}
			}
		});
		// merge parentCols in to RawCols
		rawCols = Object.assign({}, rawCols, parentCols)
		
		let colKeys = Object.keys(rawCols)
		let touchAction;
		let tierConfig;

		// get listing tier to listing config tier relation
		let listingTier = listingToTierMap[m1Key] 
		let listingConfigTier = listingConfigTierlist[listingTier]

		for (let [m2Key, m2Data] of Object.entries(window.flatData[listingGroupM1Key])) {
			if (m2Key === listingConfigTier) {
				tierConfig = {
					touchAction: m2Data.touch_action,
					mobileButtonLabel: m2Data.mobile_button_label,
					mobileClickEvent: m2Data.mobile_click_event,
					mobileButtonOrder: m2Data.mobile_button_order,
					mobileDialing: m2Data.mobile_dialing,
					mobileDisplay: m2Data.mobile_display,
					mobileParentList: m2Data.mobile_parent_list,
					mobileWayfinding: m2Data.mobile_wayfinding,
					showSubNavButton: m2Data.show_sub_nav_button,
				}
				touchAction = m2Data.touch_action
			}
		}

		let tierToListingColumnGroupMap = []

		for (let listingColumnGroup of listingGroup) {
			if (Object.keys(window.flatData[listingColumnGroup.m1Key]).includes(listingConfigTier)) {
				tierToListingColumnGroupMap.push(listingColumnGroup)
			}
		}

		let colGroups = Object.keys(rawCols)
			.map((colM1Key) => {
				for (let listingColumGroup of tierToListingColumnGroupMap) {
					for (let [ , colObj] of Object.entries(listingColumGroup.columns)) {
						if (colM1Key === colObj.m1Key) {
							let filterData = [];
							let trimmedFilterData = [];
							if (colObj.filter) {
								try {
									filterData = JSON.parse(colObj.filter);
									trimmedFilterData = filterData.map((filter) => {
										return filter.trimStart();
									});
								} catch (err) {
									console.error('Failed parsing filterData', err);
								}
							}
							return {
								m1Key: colM1Key,
								dataName: colObj.dataName,
								mobile_text_align: colObj.mobile_text_align,
								mobile_width: colObj.mobile_width,
								order: listingColumGroup.order,
								name: window.flatData[listingColumGroup.m1Key].display_name,
								sort: colObj.sort,
								text_align: colObj.text_align,
								filter: trimmedFilterData,
								width: colObj.width,
								// TODO: v2 handle grouped columns sort and width
							};
						}
					}
				}

				return false;
			})
			.filter((obj) => {
				if (obj) {
					return obj;
				}

				return false;
			})
			.map((col) => {
				// TODO: v2 return [col1, col2] as grouped col
				return col;
			});
		// TODO: v1 sort cols based on column group order
		// TODO: v2 sort cols based on column group order and column order

		return {
			settings: getComponentSettings(m1Key),
			rawCols,
			colGroups,
			vals: [],
			listings,
			listingTier: listingTier + 1,
			parentM1Keys,
		};
	}

	const getColumnType = function (listingColumn) {
		for (let [key, ] of Object.entries(listingColumn)) {
			if (key.startsWith('listing_column_type')) {
				return window.flatData[key].display_name;
			}
		}
	}

	const getListingVals = function (k, listingGroup, obj, n = 0) {
		let colsKey = `${k}_${n}`;

		for (let i = 0; i < obj.listings.length; i++) {
			for (let j = 0; j < obj.listings[i].listings.length; j++) {
				getListingVals(k, listingGroup, obj.listings[i], n + 1);
			}

			obj.listings[i].colGroups = [...listingGroup.cols[colsKey]];
			obj.listings[i].vals = [...listingGroup.cols[colsKey]].map((col) => {
			let listingValue = obj.listings[i].rawCols[col.m1Key] || '';
			if (typeof listingValue === 'string') {
				let jsonPhoneData;
				let trimmedValue = listingValue.trim();

				if (trimmedValue.startsWith('{') || trimmedValue.startsWith('[')) {
				try {
					jsonPhoneData = JSON.parse(trimmedValue);
				} catch (error) {
					console.error('Error parsing JSON:', error);
				}
				}

				if (jsonPhoneData && typeof jsonPhoneData === 'object' && 'cc' in jsonPhoneData) {
				let phoneNumber = `${jsonPhoneData.area}-${jsonPhoneData.n}`;
				return phoneNumber || '';
				}
			}
			return listingValue || '';
			});

			let detailsObj = {};
			for (let [key, value] of Object.entries(obj.listings[i].rawCols)) {
				let name = window.flatData[key].display_name;
				
				// Primary Phone for dialing
				let type = getColumnType(window.flatData[key]);
				if (type === 'Primary Phone') {
					name = '_PRIMARY';
				}

				if (detailsObj[name] && detailsObj[name] === '') {
					detailsObj[name] = value;
				} else if (!detailsObj[name]) {
					detailsObj[name] = value;
				}	
			}

			obj.listings[i].details = detailsObj;
		}
	}

	const getListingGroupCols = function (k, listingGroup, obj, n = 0) {
		let fullColumns = [],
			colsKey = `${k}_${n}`;

		for (let i = 0; i < obj.listings.length; i++) {
			for (let j = 0; j < obj.listings[i].listings.length; j++) {
				getListingGroupCols(k, listingGroup, obj.listings[i], n + 1);
			}

			if (fullColumns.length < obj.listings[i].colGroups.length) {
				fullColumns = [...obj.listings[i].colGroups];
			}
		}

		listingGroup.cols[colsKey] = fullColumns.sort((a, b) => {
			if (a.order > b.order) {
				return 1;
			}

			return -1;
		})
	}

	const getListingCollection = function (m1Key, columnGroups) {
		let m1Data = window.flatData[m1Key];
		m1Data.m1Key = m1Key;
		let listingConfigTierlist = listingCollectionToListingConfigTierMap[m1Key]

		let listingGroupToParentComponentMap = {};
		for (let [m1Key, m1Data] of Object.entries(window.flatData)) {
			if (m1Key.startsWith('component.')) {
				for (let m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('listing_group.')) {
						listingGroupToParentComponentMap[m2Key] = m1Key;
					}
				}
			}
		}

		let listingGroups = {};
		for (let [listingGroupM1Key, m1Data] of Object.entries(columnGroups)) {
			let listings = [];
			for (let m2Key of Object.keys(window.flatData[m1Key])) {
				if (m2Key.startsWith('listing.')) {
					let listing = getListing(m2Key, m1Data, listingGroupM1Key, listingConfigTierlist);
					listing.m1Key = m2Key;

					listings.push(listing);
				}
			}

			let parentComponentM1Key = listingGroupToParentComponentMap[listingGroupM1Key];
			listingGroups[listingGroupM1Key] = {
				componentM1Key: parentComponentM1Key,
				'settings': getComponentSettings(parentComponentM1Key),
				cols: {},
				listings,
			};
		}

		for (let [k, listingGroup] of Object.entries(listingGroups)) {
			getListingGroupCols(k, listingGroup, listingGroup);
		}
		for (let [k, listingGroup] of Object.entries(listingGroups)) {
			getListingVals(k, listingGroup, listingGroup);
			delete (listingGroups[k].cols);
		}

		return {
			m1Key,
			'listing_groups': listingGroups,
		};
	};

	const getListingGroupComponent = function (m1Key) {
		let listingColumnGroups = [];
		for (let [m2Key, ] of Object.entries(window.flatData[m1Key])) {
			if (m2Key.startsWith('listing_group.')) {
				for (let [key, columnGroup] of Object.entries(window.flatData[m2Key])) {
					if (key.startsWith('listing_column_group.')) {
						columnGroup.m1Key = key;
						columnGroup.columns = {};

						let col = {};
						let displayName;
						for (let [k, v] of Object.entries(window.flatData[key])) {
							if (k === 'display_name') {
								displayName = v;
							}

							if (k.startsWith('listing_column.')) {
								col.m1Key = k;
								for (let [_k, _v] of Object.entries(v)) {
									col[_k] = _v;
								}
							}
							if (k.startsWith('listing_config.')) {
								for (let [configKey, configValue] of Object.entries(window.flatData[k])) {
									if (configKey === 'display_name') {
										col.dataName = configValue;
									}
								}
							}
						}
						columnGroup.columns[displayName] = col;
						listingColumnGroups.push(columnGroup);
					}
				}
			}
		}

		return {
			settings: getComponentSettings(m1Key),
			'column_groups': listingColumnGroups,
		};
	};

	const getListingCollectionComponent = function (parentComponentM1Key) {
		let listingGroupComponents = {};
		for (let [m2Key, ] of Object.entries(window.flatData[parentComponentM1Key])) {
			// TODO: check for specific `component_type`!
			if (m2Key.startsWith('component.')) {
				let listingGroupComponent = getListingGroupComponent(m2Key);
				listingGroupComponents[m2Key] = listingGroupComponent;
			}
		}

		let columnGroups = {};
		for (let [m1Key, m1Data] of Object.entries(listingGroupComponents)) {
			for (let m2Key of Object.keys(window.flatData[m1Key])) {
				if (m2Key.startsWith('listing_group.')) {
					columnGroups[m2Key] = m1Data.column_groups;
				}
			}
		}

		for (let [m2Key, ] of Object.entries(window.flatData[parentComponentM1Key])) {
			if (m2Key.startsWith('listing_collection.')) {
				let listingCollection = getListingCollection(m2Key, columnGroups);
				listingCollection.settings = getComponentSettings(parentComponentM1Key);

				return listingCollection;
			}
		}
	}

	const data = {};
	if (typeof typedComponents['listing_collection'] !== 'undefined') {
		Object.keys(typedComponents['listing_collection']).forEach((componentM1Key) => {
			let listingCollection = getListingCollectionComponent(componentM1Key);
			data[listingCollection.m1Key] = listingCollection;
		});
	}

	return data;
}
// ================ END App.js ETL ================




// ================ ListingsGroup.js ================
const getColumnsAndListings = function (tmpObj, isCombined = false, isFirst = false, tierColGroups = []) {
	let listings = [];

	// Don't mutate props/state
	let obj = JSON.parse(JSON.stringify(tmpObj));

	if (obj.listings) {
		obj.listings.forEach((listing) => {
			// Only override tierColGroups if there is a length of listing.colGroups
			if (listing.colGroups.length > 0) {
				tierColGroups = listing.colGroups;
				listings.push(listing);

				if (isCombined) {
					(listing.listings || []).forEach((childListing) => {
						childListing.colGroups = tierColGroups;
						listings.push(childListing);
					});
					listing.listings = [];
				}
			} else {
				let [childColGroups, childListings,] = getColumnsAndListings(listing, false, false, tierColGroups);
				tierColGroups = childColGroups;
				listings.push(...childListings);
			}
		});
	}

	let sortedCols = [...tierColGroups].filter((c) => {
		if (c.sort > 0) {
			return true;
		}
		
		return false;
	});

	sortedCols.sort((i, j) => {
		if (i.sort > j.sort) {
			return 1;
		} else {
			return -1;
		}
	});

	listings.sort(sortByCols.bind(listings, sortedCols));

	return [tierColGroups, listings];
}

const sortByNumber = function (a, b) {
	return Number(a) - Number(b);
}

const sortByCols = function (sortedCols, a, b) {
	if (a.rank || b.rank) {
		let aRank = Number(a.rank || null);
		let bRank = Number(b.rank || null);

		if (aRank > bRank) {
			return -1;
		}
		if (aRank < bRank) {
			return 1;
		}
	}

	let i = 0;
	while (sortedCols.length > i) {
		let sortCol = sortedCols[i];
		i++;

		let { order, } = sortCol;

		let aCol = a.vals[order - 1];
		let bCol = b.vals[order - 1];

		if (typeof aCol === 'number' && typeof bCol === 'number') {
			sortCol.sortMethod = sortByNumber;
		}

		if (sortCol.sortMethod) {
			let n = sortCol.sortMethod(aCol, bCol);
			if (n !== 0) {
				return n;
			};

			break;
		}

		if (typeof aCol === 'string') {
			aCol = aCol.toLowerCase();
		}

		if (typeof bCol === 'string') {
			bCol = bCol.toLowerCase();
		}

		if (aCol > bCol) {
			return 1;
		}
		if (aCol < bCol) {
			return -1;
		}
	}
}
// ================ END ListingsGroup.js ================


export const getTLCUUID = () => {
	const url = window.location.search;
	const urlParams = new URLSearchParams(url);
	let tlcUUID = urlParams.get('id');

	if (!tlcUUID) {
		const urlParams = new URLSearchParams(window.location.search);

		const encodedSystemUUID = urlParams.get('s');
		const base64SystemUUID = atob(encodedSystemUUID);
		const decodedSystemUUID = decodeBase64ToHex(base64SystemUUID);
		const formattedSystemUUID = `${decodedSystemUUID.slice(0, 8)}-${decodedSystemUUID.slice(8, 12)}-${decodedSystemUUID.slice(12, 16)}-${decodedSystemUUID.slice(16, 20)}-${decodedSystemUUID.slice(20)}`;
		tlcUUID = formattedSystemUUID;
	}

	return tlcUUID;
};

const getOpeningList = () => {
	const url = window.location.search;
	const urlParams = new URLSearchParams(url);

	let list = urlParams.get('list');

	if(!list && window.newData.mobileSettings.mobile_default_to_list) {
		list = "0";
	}

	return list;
}

const decodeBase64ToHex = (base64Str) => {
	// Decode from base64
	const binaryString = atob(base64Str);
	let hexString = '';
	for (let i = 0; i < binaryString.length; i++) {
		let hex = binaryString.charCodeAt(i).toString(16);
		hexString += ('00' + hex).slice(-2); // Ensure two digits
	}
	return hexString;
};

const getLiveData = () => {
	let url = new URL(window.location.href);
	let updateStr = `?update=${url.searchParams.get('update')}`;

	const broncoURI = broncoURLENV + `/component/mobile${updateStr}`;
	// const broncoURI = `http://127.0.0.1:8080/component/mobile`;
	// const broncoURI = `https://bronco-server-ciprod.touchsource.com/component/mobile`;
	
	let tlcUUID = getTLCUUID();
	let listingUUID = ''
	let landmarkID = ''

	const urlParams = new URLSearchParams(window.location.search);
	if (!urlParams.get('id')) {
		const encodedSystemUUID = urlParams.get('s');
		const base64SystemUUID = atob(encodedSystemUUID);
		const decodedSystemUUID = decodeBase64ToHex(base64SystemUUID);
		const formattedSystemUUID = `${decodedSystemUUID.slice(0, 8)}-${decodedSystemUUID.slice(8, 12)}-${decodedSystemUUID.slice(12, 16)}-${decodedSystemUUID.slice(16, 20)}-${decodedSystemUUID.slice(20)}`;
		tlcUUID = formattedSystemUUID;
		
		const encodedListingUUID = urlParams.get('l');
		if (encodedListingUUID) {
			const base64ListingUUID = atob(encodedListingUUID);
			const decodedListingUUID = decodeBase64ToHex(base64ListingUUID);
			const formattedListingUUID = `${decodedListingUUID.slice(0, 8)}-${decodedListingUUID.slice(8, 12)}-${decodedListingUUID.slice(12, 16)}-${decodedListingUUID.slice(16, 20)}-${decodedListingUUID.slice(20)}`;
			listingUUID = formattedListingUUID;
		}
	
		landmarkID = urlParams.get('lid');
	}

	fetch(broncoURI, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ uuid: tlcUUID }),
	})
		.then(response => {
			return response.json();
		})
		.then((data) => {
			console.log('JSON Data');
			console.log(data);

			window.flatData = data.fd || {};
			window.systemUUID = data.system_uuid || '';
			window.componentList = data.component_list || {};
			makeMaps();
			window.etlData = tmpListingETL() || {};
		
			data.setting_list.forEach((item) => {
				let settings = {};
				if (item.name === 'mobile') {
					item.value.forEach((obj) => {
						settings[obj.name] = obj.value;
					})
				}
			});

			window.newData = {};
			window.tierGroups = {};
			window.mapData = {
				showInstructions: false,
				showInstructionsIndex: 0
			};
			window.parentMap = window.parentMap || {};
			orientationClass();
			getMobileSettings();
			setMobileTheme();
			buildData();
			urlTimeout();
			showMenuOnLoad();
			searchBtnEvent();
			searchInputSelection();
			removeMask();
			window.locationMarkerUse = getSystemOrPropertyOrAccountSetting('wf_location_marker_use', `system.${window.systemUUID}`, true) || false;
			openList();

			if (tlcUUID && (listingUUID || landmarkID)) {
				let dataName = ''

				outerLoop:
				for (let [m1Key, m1Data] of Object.entries(window.newData)) {
					if (m1Key.startsWith('listing_collection.')) {
						for (let [m2Key, m2Data] of Object.entries(m1Data.listing_groups)) {
							for (let [, m3Data] of Object.entries(m2Data.listings)) {
								if (landmarkID && m3Data.details?.landmark_id === landmarkID) {
									dataName = m2Key;
									listingUUID = m3Data.m1Key.replace('listing.', '');;
									break outerLoop;
								} else if (m3Data.m1Key === `listing.${listingUUID}`) {
									dataName = m2Key;
									break outerLoop;
								}
							}
						}
					}
				}
		
				const listingCollectionButton = document.querySelector(`[data-name="${dataName}"]`);
				listingCollectionButton.click();
				setTimeout(() => {
					const listingId = `listing.${listingUUID}`;
					const listingRow = document.querySelector(`[data-name="${listingId}"]`);
					listingRow.click();
				}, 50);
			}

			if (listingUUID) {
				wayfindingMapStartup(`listing.${listingUUID}`);
			} else {
				wayfindingMapStartup();
			}
		})
		.catch((error => {
			console.error(error);
			document.getElementById('error').classList.remove('hide');
			removeMask();
		}));
}

getLiveData();

window.connections = {};

const createThemeBtn = () => {
	if (!document.getElementById('themePicker')) {
		var themeBtn = document.createElement('div');
		themeBtn.classList.add('themeBtn');
		themeBtn.setAttribute('id', 'themePicker');
		var btnName = document.createTextNode('THEME');
		themeBtn.appendChild(btnName);
		themeBtn.addEventListener('click', () => {
			swapTheme();
		});
		document.getElementById('container').appendChild(themeBtn);
	}
};

let theme = 0;
const swapTheme = () => {
	let themeArr = ['c0', 'c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9', 'c10', 'c11', 'c12', 'c13', 'c14'];
	if (theme < themeArr.length - 1) {
		theme++;
	} else {
		theme = 0;
	}
	document.getElementById('container').classList = `container ${themeArr[theme]}`;
};

const urlTimeout = () => {
	let mins = 1800000; // default 30mins
	if (window.newData.mobileSettings.mobile_timeout && window.newData.mobileSettings.mobile_timeout !== null && window.newData.mobileSettings.mobile_timeout >= 1) {
		mins = window.newData.mobileSettings.mobile_timeout * 60000;
	}

	let url = ''
	if (window.newData.mobileSettings.mobile_timeout_url && window.newData.mobileSettings.mobile_timeout_url !== null && window.newData.mobileSettings.mobile_timeout_url.length > 5) {
		url = window.newData.mobileSettings.mobile_timeout_url;
	}

	if (url) {
		setTimeout(function () {
			window.location = url;
		}, mins)
	}
};

const revealNavIcon = () => {
	if (document.getElementById('nav').classList.contains('hideNav')) {
		setTimeout(() => {
			document.getElementById('nav').classList.remove('hideNav');
			document.getElementById('nav').classList.remove('opacity0');
		}, 500);
	}
};

const getMobileSettings = () => {
	let mobileSettings = {};
	let mobileURLs = [];
	for (let [flatObjName, flatObj] of Object.entries(window.flatData)) {
		if (flatObj.description === 'mobile') { // mobile obj

			let mobileUUID = flatObjName.replace('component.', '');
			window.componentList.forEach((component) => {
				if (component.name && component.name === mobileUUID) {
					component.setting_list.forEach((setting) => {
						mobileSettings[setting.name] = setting.value;
					})
				}
			})
			window.newData.mobileSettings = mobileSettings;
		} else if (flatObj.description === 'mobile_url') {
			let mobileURLUUID = flatObjName.replace('component.', '');
			window.componentList.forEach((component) => {
				if (component.name && component.name === mobileURLUUID) {
					let linkURL = '';
					let linkText = '';
					let mobileURLOrder = 0;

					component.setting_list.forEach((setting) => {
						switch (setting.name) {
							case 'link_url':
								linkURL = setting.value;
								break;
							case 'link_text':
								linkText = setting.value;
								break;
							case 'mobile_url_order':
								mobileURLOrder = setting.value;
								break;
							default:
								console.warn(`Unhandled setting name: ${setting.name}`);
								break;
						}
					});

					mobileURLs.push([linkURL, linkText, mobileURLOrder]);
				}
			});
		}
	}
	window.newData.mobileURLs = mobileURLs;
};

const setMobileTheme = () => {
	let mobile_theme = window.newData.mobileSettings.mobile_theme;
	document.getElementById('container').classList.add(`c${mobile_theme}`);
	document.getElementById('wayfindingModal').classList.add(`c${mobile_theme}`);
};

const buildMobileSettings = (collectionName, obj) => {
	// build every tier's mobile settings
	console.log('the groups', obj.listing_groups)

	for (let [listingGroupName, ] of Object.entries(obj.listing_groups)) {
		console.log('current listing group', listingGroupName)
		for (let [configName, ] of Object.entries(window.flatData)) {
			if (configName.startsWith('listing_config')) {
				// checking to see that it is a tier 0 listing config
				if (window.flatData[collectionName][configName]) {
					setMobileTierSettings(configName, collectionName, listingGroupName);
				}
			}
		}
	}
};

const setMobileTierSettings = (configName, collectionName, listingGroupName) => {
	let listingConfigObj = window.flatData[configName];
	let listingGroupToListingConfigData = window.flatData[listingGroupName][configName];

	if (!listingGroupToListingConfigData) {
		return;
	}

	// Check if the collectionName key exists in listTierSettings, if not, initialize it as an object
	if (!window.newData.listTierSettings[collectionName]) {
		window.newData.listTierSettings[collectionName] = {};
	}

	let key = configName; // Using configName as the key
	let listingConfigParentName;

	for (let [configName, config] of Object.entries(window.flatData)) {
		if (configName.startsWith('listing_config')) {	
			if (config[key]) {
				listingConfigParentName = config.display_name;
			}
		}
	}

	// Check if the key already exists
	if (window.newData.listTierSettings[collectionName][key]) {
		// Overwrite if new data has a non-empty mobile_button_label
		if (listingGroupToListingConfigData.mobile_button_label !== '') {
			window.newData.listTierSettings[collectionName][key] = {
				mobile_button_label: listingGroupToListingConfigData.mobile_button_label,
				mobile_button_order: listingGroupToListingConfigData.mobile_button_order,
				mobile_click_event: listingGroupToListingConfigData.mobile_click_event,
				mobile_dialing: listingGroupToListingConfigData.mobile_dialing,
				mobile_display: listingGroupToListingConfigData.mobile_display,
				mobile_parent_list: listingGroupToListingConfigData.mobile_parent_list,
				mobile_wayfinding: listingGroupToListingConfigData.mobile_wayfinding,
				listing_config_parent_name: listingConfigParentName
			};
		}
	} else {
		// If the key doesn't exist, just set the new data
		window.newData.listTierSettings[collectionName][key] = {
			mobile_button_label: listingGroupToListingConfigData.mobile_button_label,
			mobile_button_order: listingGroupToListingConfigData.mobile_button_order,
			mobile_click_event: listingGroupToListingConfigData.mobile_click_event,
			mobile_dialing: listingGroupToListingConfigData.mobile_dialing,
			mobile_display: listingGroupToListingConfigData.mobile_display,
			mobile_parent_list: listingGroupToListingConfigData.mobile_parent_list,
			mobile_wayfinding: listingGroupToListingConfigData.mobile_wayfinding,
			listing_config_parent_name: listingConfigParentName
		};
	}


	for (let [nextConfigName, ] of Object.entries(listingConfigObj)) {
		if (nextConfigName.startsWith('listing_config')) {	
			setMobileTierSettings(nextConfigName, collectionName, listingGroupName);
		}
	}
};

const buildListingGroup = (collectionName, groups) => {
	let combined = false;
	let dataName;

	let tierGroups = {
		tier0: [],
	};

	for (let [listName, listGroupObj] of Object.entries(groups)) {
		dataName = listName

		// build all child listings
		let findChildren = (listing, tierNum, combined) => {
			if (listing.listings.length > 0) {
				let currentTierNum = tierNum + 1;
				if (combined === true) {
					currentTierNum = 0;
				}

				listing.listings.forEach((childListing) => {

					if (childListing.colGroups.length > 0) {
						if (tierGroups[`tier${currentTierNum}`] === undefined) {
							tierGroups = {
								...tierGroups,
								[`tier${currentTierNum}`]: []
							}
						}
						let isDuplicate = false
						tierGroups[`tier${currentTierNum}`].forEach((listing) => {
							if (listing.vals.join() === childListing.vals.join()) {
								isDuplicate = true
							}
						});

						if (!isDuplicate) {
							tierGroups[`tier${currentTierNum}`].push(childListing);
						}
					}

					findChildren(childListing, currentTierNum, combined);
				});
			}
		}

		const filterList = buildFilterList(listGroupObj.listings);
		
		const filteredListings = applyFilters(filterList);


		// build tier0 listings
		filteredListings.forEach((listing) => {
			let tierNum = 0;
			combined = false;
			if (listing.colGroups.length > 0) {
				tierGroups.tier0.push(listing);
			}
			if (listGroupObj.settings.combined.value === true) {
				combined = true;
			}

			findChildren(listing, tierNum, combined);
		});	
	}
	console.log('tierGroups', tierGroups);
	buildNavigation(collectionName, tierGroups, dataName);
};

window.buildListingTierGroups = (collectionName, groups) => {
	let combined = false;
	let dataName;

	let tierGroups = {
		tier0: []
	}

	for (let [listName, listGroupObj] of Object.entries(groups)) {
		dataName = listName

		// build all child listings
		let findChildren = (listing, tierNum, combined) => {
			if (listing.listings.length > 0) {
				let currentTierNum = tierNum + 1;
				if (combined === true) {
					currentTierNum = 0;
				}

				listing.listings.forEach((childListing) => {

					if (childListing.colGroups.length > 0) {
						if (tierGroups[`tier${currentTierNum}`] === undefined) {
							tierGroups = {
								...tierGroups,
								[`tier${currentTierNum}`]: []
							}
						}
						let isDuplicate = false
						tierGroups[`tier${currentTierNum}`].forEach((listing) => {
							if (listing.vals.join() === childListing.vals.join()) {
								isDuplicate = true
							}
						});

						if (!isDuplicate) {
							tierGroups[`tier${currentTierNum}`].push(childListing);
						}
					}

					findChildren(childListing, currentTierNum, combined);
				});
			}
		}

		listGroupObj.listings.forEach((listing) => {
			let tierNum = 0;
			combined = false;
			if (listing.colGroups.length > 0) {
				tierGroups.tier0.push(listing);
			}
			if (listGroupObj.settings.combined.value === true) {
				combined = true;
			}

			findChildren(listing, tierNum, combined);
		});	
		
	}
	window.tierGroups[collectionName] = tierGroups
}

const buildNavigation = (collectionName, tierGroups, dataName) => {
	let dataObj = 'newData';
	window.newData[collectionName] = window.newData[collectionName] || {
		listing_groups: {}
	};
	let configTierList = listingCollectionToListingConfigTierMap[collectionName]
	let tierNum = 0;

	// build data into tier groups
	for (let [thisKey, thisValue] of Object.entries(tierGroups)) {
		let listTier = configTierList[tierNum]

		if (tierGroups[thisKey].length === 0) {
			tierNum++
			continue;
		}

		window.newData[collectionName].listing_groups = {
			...window.newData[collectionName].listing_groups,
			[`${thisKey}.${dataName}`]: {
				listings: thisValue,
			},
		};

		let btnName = tierGroups[thisKey][0].colGroups[0].dataName;
		let btnOverride = window.newData.listTierSettings[collectionName][listTier].mobile_button_label;
		console.log(btnOverride, btnOverride)
		if (btnOverride && btnOverride !== undefined && btnOverride !== undefined && btnOverride.length >= 1) {
			btnName = btnOverride;
		}
		let sortValue = window.newData.listTierSettings[collectionName][listTier].mobile_button_order;
		
		let mobileDisplay = window.newData.listTierSettings[collectionName][listTier]?.mobile_display;
		if (mobileDisplay) {
			// only show the nav button for the listing_group if moble_display is true
			// by keeping this check down here and letting all the above process we can
			// have something like T1(mobile_display: false) -> T2(mobile_display: false) -> T3(mobile_display: true)
			window.newData.nav.push({
				order: sortValue,
				value: `<div class="btn btn-nav" data-obj="${dataObj}" data-tier="${tierNum}" data-order="${sortValue}" data-collection="${collectionName}" data-name="${thisKey}.${dataName}">${btnName}</div>`,
			});
		}

		tierNum++;
	}
};

const btnEvents = function (e) {
	let dataObj = this.dataset.obj;
	let collection = this.dataset.collection;
	let name = this.dataset.name;
	let group = window[dataObj][collection].listing_groups[name];
	let tier = listingCollectionToListingConfigTierMap[collection][this.dataset.tier];
	
	window.currentList = group; // used by buildList()
	window.currentSettings = window.newData.listTierSettings[collection][tier]; // used by buildList()
	window.currentCollection = collection

	clearListItemSelection();

	// close and clear search input
	document.getElementById('search').value = '';
	document.getElementById('searchInputCont').classList.add('idle');
	document.getElementById('clearIcon').classList.add('hideIcon');

	buildList();
	menu();
	revealNavIcon();
	if (window.mobileOrientation === 'vertical') {
		// fixes path not loading properly afteer using menu
		resetMap();
	}

	dataLayerPush({
		event: "Click",
		label: e.target.innerText,
		scope: "tier"+this.dataset.tier,
	});
};

const buildData = () => {
	let data = window.etlData;
	window.newData.listTierSettings = {};
	window.newData.existingConfigs = {};
	window.newData.nav = [];

	// for each collection
	for (let [collectionName, collectionObj] of Object.entries(data)) {
		let groups = collectionObj.listing_groups;
		
		buildMobileSettings(collectionName, collectionObj);
		window.buildListingTierGroups(collectionName, groups);
		buildListingGroup(collectionName, groups);
	}

	// Add URL, Property Info here?
	createURLNavs();
	sortAndCreateNav();
	addClientURLs();

	if (window.newData.mobileSettings.enable_branding) {
		addTouchsourceBranding();
	}
};

const createURLNavs = () => {
	const sortOffset = window.newData.nav.reduce((greater, curr) => {
		if (curr.order >= greater) {
			return curr.order + 1;
		}
		return greater;
	}, 0);

	window.newData.mobileURLs = window.newData.mobileURLs.sort((a, b) => {
		if (a[2] > b[2]) {
			return 1;
		}
		return -1;
	});
	
	window.newData.mobileURLs.forEach((urlPair) => {
		window.newData.nav.push({
			order: sortOffset,
			value: `<a href="${urlPair[0]}" target="_blank"><div class="btn btn-nav" data-order="${sortOffset}">${urlPair[1]}</div></a>`
		});
	});
};

const sortAndCreateNav = () => {
	let buttons = '';
	window.newData.nav.sort((a, b) => {
		return a.order - b.order;
	});

	window.newData.nav.forEach((button) => {
		buttons += button.value;
		buttons += `<div class="navLine"></div>`;
	});
	document.getElementById('menuBtns').innerHTML = buttons;

	let menuButtons = document.getElementsByClassName('btn-nav');

	for (var i = 0; i < menuButtons.length; i++) {
		menuButtons[i].addEventListener('click', btnEvents, false);
	}
};

const openList = () => {
	const menuButtons = document.getElementsByClassName('btn-nav');
	const openOnListIndex = getOpeningList();

	if (openOnListIndex) {
		console.log('clicked')
		menuButtons[parseInt(openOnListIndex)].click();
	}
};

const addClientURLs = () => {
	let fakeUrlList = [];

	if (fakeUrlList && fakeUrlList.length > 0) {
		fakeUrlList.forEach((url, i) => {
			var urlDiv = document.createElement('div');
			urlDiv.classList.add('btn');
			var urlText = document.createTextNode(url);
			urlDiv.appendChild(urlText);
			urlDiv.addEventListener('click', () => {
				window.open(url, '_blank');
			});
			document.getElementById('menuBtns').appendChild(urlDiv);
	
			var lineDiv = document.createElement('div');
			lineDiv.classList.add('navLine');
			document.getElementById('menuBtns').appendChild(lineDiv);
		});
	}
};

const addTouchsourceBranding = () => {
	var tsDiv = document.createElement('div');
	tsDiv.classList.add('btn');
	tsDiv.classList.add('tsLogoContainer');
	var textnode = document.createTextNode('TouchSource');
	tsDiv.appendChild(textnode);
	tsDiv.addEventListener('click', () => {
		dataLayerPush({
			event: 'Click',
			label: 'TouchSource Logo',
		});
		window.open('https://touchsource.com/contactless-solutions', '_blank');
	});

	document.getElementById('menuBtns').appendChild(tsDiv);

	var tsLogoCont = document.querySelector('.tsLogoContainer')
	var tsLogo = new Image();
	
	let logoColor = 0;
	switch (window.newData.mobileSettings.mobile_tslogo_color) {
		case 1:
			logoColor = 'https://s3.amazonaws.com/ts-condor-assets/images/logos/tsLogoWhite.svg'; 
			break;
		case 2:
			logoColor = 'https://s3.amazonaws.com/ts-condor-assets/images/logos/tsLogoWhiteBlue.svg'; 
			break;
		case 3:
			logoColor = 'https://s3.amazonaws.com/ts-condor-assets/images/logos/tsLogoBlack.svg'; 
			break;
		case 4:
			logoColor = 'https://s3.amazonaws.com/ts-condor-assets/images/logos/tsLogoBlackBlue.svg'; 
			break;
		default:
			logoColor = ''; 
			break;
	}
	tsLogo.src = logoColor; 
	tsLogo.classList.add('tsLogo');
	tsLogo.onload = function () {
		tsLogoCont.classList.add('loaded');
		tsLogo.classList.add('loaded');
	};
	tsLogoCont.appendChild(tsLogo);
};

const showMenuOnLoad = () => {
	let menu = document.getElementById('menu');
	let nav = document.getElementById('nav');
	nav.classList.add('hideNav');
	nav.classList.add('opacity0');

	menu.classList.add('noTransition');
	menu.classList.remove('slide');
	nav.dataset.open = true;

	setTimeout(function () {
		menu.classList.remove('noTransition');
	}, 500);
};

const removeMask = () => {
	setTimeout(function () {
		document.getElementById('loadScreen').classList.add('opacity0');
	}, 500);
	setTimeout(function () {
		document.getElementById('loadScreen').classList.add('loadHide');
	}, 1300);
};

const buildList = (text) => {
	window.scrollTo(0, 0);
	document.getElementById('listings').scrollTo(0, 0);

	let listingGroup = window.currentList;
	let [, listingsData] = getColumnsAndListings(listingGroup, false, false);
	let eventType = window.currentSettings.mobile_click_event;
	let mobile_dialing = window.currentSettings.mobile_dialing;
	let mobile_wayfinding = window.currentSettings.mobile_wayfinding;
	let content = '';
	let columnWidths = [];
	let headerCols = [];
	let searchText = '';
	if (text) {
		searchText = text.toLowerCase();
	}

	let parentlistingTier = window.currentList.listings[0]?.listingTier - 2
	window.listingNameCol = window.currentList.listings[0]?.colGroups[0]?.m1Key;
	parentlistingTier = "tier" + parentlistingTier
	parentlistingTier = window.tierGroups[window.currentCollection][parentlistingTier]
	
	let replayBtn = '';
	let useStoreLogo = false;

	if (mobile_wayfinding === true) {
		replayBtn = window.locationMarkerUse === true ? '' : `<div class="replayButton" onclick="replay()"><i class="fa-solid fa-rotate-right headerIcon"></i>REPLAY</div>`;
	}

	let filteredList = [];
	
	if (searchText.length > 0) {
		filteredList = listingsData.filter((listing) => {
			let match = false;
			listing.vals.forEach((val) => {
				if (val.toLowerCase().includes(searchText)) {
					match = true;
				}
			});
			return match;
		});	
	}
	
	if (filteredList.length > 0) {
		listingsData = filteredList;
	}

	let numberOfResults = listingsData.length;
	
	if (searchText.length > 0 && filteredList.length === 0) {
		listingsData[0].colGroups.forEach((col, i) => {
			headerCols[i] = col.name;
			columnWidths[i] = col.mobile_width;
		});
		content += `<div class="listItem"><div class="data0">No results found.</div></div>`;
	} else if (numberOfResults < 250) {
		listingsData.forEach((obj) => {
			let websiteBtn = '';
			let storeLogo = `<div class="storeLogoPlaceholder"><i class="fa-solid fa-location-dot"></i></div>`;
			let detailsText = '';
			let hoursText = '';
			let categoriesText = '';
			let addLine = false;

			obj.colGroups.forEach((col, i) => {
				headerCols[i] = col.name;
				columnWidths[i] = col.mobile_width;
			});

			let phoneIcon = '';
			let dialBtn = '';
			let hasEvent = '';
			
			if (obj.details.description && obj.details.description !== null && obj.details.description.length > 0) {
				const formattedString = obj.details.description.replace(/\n/g, '<br>');
				detailsText = (
					`<div class="detailsText description">
						${formattedString}
						<div class="scrollGradientMask"></div>
					</div>`
				);
			}

			if (obj.details.store_logo && obj.details.store_logo !== null && obj.details.store_logo.length > 0) {
				storeLogo = `<img src="${obj.details.store_logo}" alt="Store Logo" class="storeLogo" onError="${(event) => { event.target.src = ''; event.target.style.display = 'none'; }}" />`;
				useStoreLogo = true;
			}
			
			if (window.currentSettings.mobile_parent_list && parentlistingTier) {
				const matchingParents = parentlistingTier.filter((parent) => {
				  const flattenedKeys = obj.parentM1Keys.flat();
				  return flattenedKeys.includes(parent.m1Key);
				});
		  
				const categoryBtnsHTML = matchingParents
				  .map((parent) => {
					window.parentMap[parent.m1Key] = parent;
					return `
					  <div 
						class="categoryBtn" 
						data-parent-key="${parent.m1Key}"
					  >
						${parent.vals[0]}
					  </div>
					`;
				  })
				  .join("");
		  
				categoriesText = `
				  <div class="detailsText categories">
					<div class="categoriesHeader">${window.currentSettings.listing_config_parent_name}</div>
					<div class="categoryBtnWrapper">
					  ${categoryBtnsHTML}
					</div>
				  </div>
				`;
			  }

			if (obj.details.hours && obj.details.hours !== null && obj.details.hours.length > 0) {
				const formattedString = obj.details.hours.replace(/\n/g, '<br>');
				hoursText = `<div class="detailsText hours">${formattedString}</div>`;
			}

			if (obj.details.mall_store_url && obj.details.mall_store_url !== null && obj.details.mall_store_url.length > 0) {
				const icon = `<i class="fa-solid fa-link headerIcon"></i>`;
				websiteBtn = `<a class="websiteButton" href="${obj.details.mall_store_url}" target="_blank">${icon} WEBSITE</a>`;
			}

			if (mobile_dialing === true && obj.details._PRIMARY && obj.details._PRIMARY !== null && obj.details._PRIMARY.length > 0) {
				phoneIcon = `<div class="phoneCont"><svg height='100px' class="phoneIcon" width='100px'  fill="#000000" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve"><path d="M34.3,16.635c1.758,1.757,3.522,3.509,5.272,5.274c3.086,3.113,3.092,6.744,0.018,9.833  c-2.205,2.216-4.397,4.445-6.645,6.616c-0.594,0.573-0.641,1.045-0.34,1.767c1.476,3.545,3.61,6.684,6.011,9.636  C43.442,55.694,48.9,60.96,55.409,65.046c1.394,0.875,2.936,1.512,4.388,2.3c0.747,0.405,1.259,0.276,1.857-0.342  c2.199-2.273,4.451-4.496,6.698-6.723c2.943-2.916,6.637-2.922,9.593,0.006c3.612,3.578,7.208,7.173,10.789,10.781  c3.004,3.026,2.98,6.724-0.041,9.772c-2.041,2.06-4.198,4.015-6.128,6.173c-2.809,3.141-6.333,4.158-10.364,3.936  c-5.859-0.322-11.264-2.266-16.469-4.796c-11.57-5.623-21.461-13.416-29.735-23.265c-6.118-7.282-11.174-15.207-14.499-24.158  C9.89,34.401,8.74,29.967,9.09,25.277c0.216-2.894,1.303-5.356,3.436-7.386c2.29-2.181,4.472-4.475,6.728-6.693  c2.954-2.905,6.649-2.899,9.61,0.008C30.692,13,32.489,14.824,34.3,16.635z"></path></svg></div>`;
				hasEvent = 'hasEvent';
				let jsonPhoneData = JSON.parse(obj.details._PRIMARY);
				let primaryPhoneNum = `+${jsonPhoneData.cc} ${jsonPhoneData.area}-${jsonPhoneData.n}`;
				dialBtn = `<a target="_parent" class="call" href="tel:${primaryPhoneNum}"><i class="fa-solid fa-phone headerIcon"></i> CALL</a>`;
			}

			if (mobile_wayfinding === true) {
				hasEvent = 'hasEvent';
			}

			let line = (mobile_dialing === true && eventType === 1) ? phoneIcon : '';

			obj.vals.forEach((data, i) => {
				if (data === null || data === undefined) {
					data = '';
				}

				if (i === 0 && columnWidths[i] > 0) {
					line += `<div class="data${i}">${data}</div>`;
				} else if (columnWidths[i] > 0 && obj.vals[i]) {
					line += `<div class="listItemRow"><div class="dataFieldName">${obj.colGroups[i].name}: </div><div class="data${i}">${data}</div></div>`;
				}

				if (searchText === '' || (searchText !== '' && data.toLowerCase().includes(searchText))) {
					addLine = true;
				}
			});

			if (addLine === true) {
				content += `
						<div class="listItem ${hasEvent} unSelected" data-name="${obj.m1Key}">
							<div class="closeListItem"><i class="fa-regular fa-circle-xmark"></i></div>
							${storeLogo}
							<div class="lineWrap">
								${line}
							</div>
							<div class="listDetailsBtnsRow">
								${replayBtn}
								${websiteBtn}
								${dialBtn}
							</div>
							${categoriesText}
							${hoursText}
							<div id="nearbyLocations" class="nearbyLocations"></div>
							${detailsText}
				
						</div>
				`;
			}
		});
	} else {
		listingsData[0].colGroups.forEach((col, i) => {
			headerCols[i] = col.name;
			columnWidths[i] = col.mobile_width;
		});
		content += `<div class="listItem"><div class="data0">Found ${numberOfResults} results, please refine your search.</div></div>`;
	}

	document.getElementById('listGroup').innerHTML = content;

	if (useStoreLogo === false) {
		document.querySelectorAll('.storeLogoPlaceholder').forEach((logo) => {
			logo.style.display = 'none';
		});
	}

	// ====== build header ======
	let headerData = '';
	headerCols.forEach((data, i) => {
		if (columnWidths[i] > 0) {
			headerData += `<div class="listHeader${i}">${data.toUpperCase()}</div>`;
		}
	});

	let instructionText = '';

	if (mobile_dialing) {
		instructionText = 'Touch a Listing to Call'
	}
	if (mobile_wayfinding) {
		instructionText = 'Tap a listing for a wayfinding map'
	}
	if (mobile_dialing && mobile_wayfinding) {
		instructionText = 'Touch a Listing for More Information'
	}
	
	let instructionElement = `<div class="instructionText">${instructionText}</div>`;

	headerData += instructionElement;

	let listHeader = document.getElementById('listHeader');
	listHeader.innerHTML = headerData;
	document.getElementById('search').setAttribute('placeholder', `Search Listings`);
	document.getElementById('loadText').classList.add('hide');

	createListingEvents(eventType);
	const catButtons = document.querySelectorAll('.categoryBtn');
  	catButtons.forEach((btn) => {

    btn.addEventListener('click', () => {
      const m1Key = btn.getAttribute('data-parent-key');
      if (!m1Key) return;

      const parentObj = window.parentMap[m1Key];
      if (!parentObj) return;

      window.currentList = parentObj;
      categoryDropdown.style.display = 'none';
      clearListItemSelection();
      window.buildList();
    });
  });
};

const searchBtnEvent = () => {
	document.getElementById('searchIconBtn').addEventListener('click', toggleSearch);
};

const toggleSearch = () => {
	let searchCont = document.getElementById('searchInputCont');
	if (searchCont.classList.contains('idle')) {
		searchCont.classList.remove('idle');
		document.getElementById('search').focus();
		dataLayerPush({
			event: 'Click',
			label: 'Search Button',
			focus: 'Focused',
		});
	} else {
		searchCont.classList.add('idle');
		clearSearchInput();
		dataLayerPush({
			event: 'Click',
			label: 'Search Button',
			focus: 'Idle',
		});
	}
}

const categoryDropdown = document.getElementById('categoryDropdown');
const searchInput = document.getElementById('search');

const searchInputSelection = () => {
	searchInput.addEventListener('click', () => {
		let showDropdown = window.currentSettings.mobile_parent_list;
		let parentlistingTier = window.currentList.listings[0]?.listingTier - 2;
		let arrayTierValue = "tier" + parentlistingTier;

		if (showDropdown && Number.isInteger(parentlistingTier)) {
			categoryDropdown.innerHTML = '';
			const sortedTierArray = window.tierGroups[window.currentCollection][arrayTierValue].sort((a, b) => {
				console.log("SORTING", a.vals[0], b.vals[0]);
				let nameA = a.vals[0];
				let nameB = b.vals[0];
				return nameA.localeCompare(nameB);
			});
			sortedTierArray.forEach((obj) => {
				if (obj.listings.length !== 0) {
					const categoryItem = document.createElement('div');
					categoryItem.className = 'category-item';
					const icon = document.createElement('i');
					icon.className = 'fa-solid fa-list headerIcon'; 
					categoryItem.appendChild(icon);
					const textNode = document.createTextNode(' ' + obj.vals[0]);
					categoryItem.appendChild(textNode);
					categoryItem.addEventListener('click', () => {
						window.currentList = obj;
						categoryDropdown.style.display = 'none';
						clearListItemSelection();
						window.buildList();
					});
					categoryDropdown.appendChild(categoryItem);
				}
			});
			categoryDropdown.style.display = 'block';
		}
	});

	document.addEventListener('click', (event) => {
		if (!searchInput.contains(event.target) && !categoryDropdown.contains(event.target)) {
			categoryDropdown.style.display = 'none'; 
		}
	});
};

window.keystroke = () => {
	document.getElementById('loadText').classList.remove('hide');
	let searchInput = document.getElementById('search');
	let searchText = searchInput.value.toLowerCase();
	clearListItemSelection();

	if (searchText === 'themepicker') {
		window.createThemeBtn();
	}

	window.scrollTo(0, 0);
	document.getElementById('listings').scrollTo(0, 0);

	setTimeout(function () {
		window.buildList(searchText);
		categoryDropdown.style.display = 'none';

		if (searchText.length > 0) {
			document.getElementById('clearIcon').classList.remove('hideIcon');
		} else {
			document.getElementById('clearIcon').classList.add('hideIcon');
		}
	}, 50)
}

// Global function to handle the cleanup
export function clearListItemSelection() {
	const listGroup = document.getElementById('listings');
	const selectedItem = document.querySelector('.selected');
	window.useNearbyLocation = '';

	document.getElementById('wayfindingModal').classList.add('hideModal');

	if (listGroup) listGroup.classList.remove('listItemSelected');
	if (selectedItem) {
		selectedItem.classList.add('unSelected');
		selectedItem.classList.remove('selected');

		const closeButton = selectedItem.querySelector('.closeListItem');
		if (closeButton) {
			closeButton.removeEventListener('click', undoActions);
		}

		const nearbyLocationsElem = selectedItem.querySelector('.nearbyLocations')
		nearbyLocationsElem.innerHTML = '';
	}
}

// Store event listeners by item to allow proper cleanup
const itemListeners = new WeakMap();

// Function to set up the initial state and event listener
function setupListItemSelection(item) {
    const listGroup = document.getElementById('listings');

    document.getElementById('wayfindingModal').classList.remove('hideModal');
    document.getElementById('listings').scrollTo(0, 0);

    if (listGroup) listGroup.classList.add('listItemSelected');

    if (item) {
        item.classList.remove('unSelected');
        item.classList.add('selected');
        updateGradient();
    }

	const closeButton = item.querySelector('.closeListItem');
	if (closeButton) {
		// Remove old listener if it exists
		const oldListener = itemListeners.get(closeButton);
		if (oldListener) {
			closeButton.removeEventListener('click', oldListener);
		}

		// Create and store new listener
		const newListener = (event) => handleCloseClick(event, item);
		itemListeners.set(closeButton, newListener);
		
		closeButton.addEventListener('click', newListener);
	}
}

function handleCloseClick(event, item) {
    undoActions(event, item);
    updateGradient();
};

// Event handler for the close button
function undoActions(event) {
	resetMap();
	event.stopPropagation();
	clearListItemSelection();
}

function removeInitialLoadClass() {
	const container = document.getElementById('container');
	if (container.classList.contains('initialLoad')) {
		container.classList.remove('initialLoad');
	}
}

const createListingEvents = (eventType) => {
	let links = document.getElementsByClassName('listItem');

	for (let i = 0; i < links.length; i++) {
		if (links[i].classList.contains('hasEvent')) {

			links[i].addEventListener('click', function (e) {
				let makeCall;

				e.stopPropagation();
				if (e.target !== this) return;
	
				if (eventType === 1 && this.querySelector('.call')) {
					let number = this.querySelector('.call').getAttribute('href');
					window.open(number, '_parent');
					makeCall = true
				}

				if (eventType !== 1) {
					if (!e.currentTarget.classList.contains('selected')) {
						const containerElem = document.getElementById('container');
						if (containerElem && window.mobileOrientation === 'vertical') {
							containerElem.classList.add('nearbyLocationDelay');
							setTimeout(() => {
								containerElem.classList.remove('nearbyLocationDelay');
							}, 100);
						}

						setupListItemSelection(this);
						removeInitialLoadClass();
						const listingUUID = links[i].dataset.name;
						renderPath(listingUUID);
					}
				}

				dataLayerPush({
					event: 'Click',
					label: e.target.innerText,
					scope: 'Tier 2',
					clickable: true,
					makeCall,
				});
			}, false);
		} else {
			links[i].addEventListener('click', function (e) {
				dataLayerPush({
					event: 'Click',
					label: e.target.innerText,
					scope: 'Tier 2',
					clickable: false,
				});
			}, false);
		}
	}

	let callBtns = document.getElementsByClassName('call');

	for (var i = 0; i < callBtns.length; i++) {
		callBtns[i].addEventListener('click', function () {
			dataLayerPush({
				event: 'Click',
				label: 'Call Button',
				makeCall: true,
			});
		}, false);
	}
}

const menu = () => {
	if (navBtn.dataset.open === 'false') {
		setTimeout(() => {
			document.getElementById('nav').dataset.open = true;
			document.getElementById('menu').classList.remove('slide');
			document.getElementById('navIcon').classList.add('open');
		}, 200);
	} else if (navBtn.dataset.open === 'true') {
		setTimeout(() => {
			document.getElementById('nav').dataset.open = false;
			document.getElementById('menu').classList.add('slide');
			document.getElementById('navIcon').classList.remove('open');
		}, 200);
	}
}

// hook navagation touch events
let navBtn = document.getElementById('nav');
if (navBtn) {
	navBtn.onclick = function () {
		dataLayerPush({
			event: 'Click',
			label: 'Navigation Button',
			opening: navBtn.dataset.open === 'true' ? 'Opened' : 'Closed',
		});
		menu();
		// clearWayfindingMessage();
	}
}

// hook search clear event
let searchClear = document.getElementById('searchClear');

const clearSearchInput = () => {
	document.getElementById('search').value = '';
	window.keystroke();
}
if (searchClear) {
	searchClear.onclick = function () {
		clearSearchInput();
	}
}

// hook error button event
let errorBtn = document.getElementById('errorBtn');
if (errorBtn) {
	errorBtn.onclick = function () {
		window.location.reload();
	}
}

// used on index.html
window.keystroke = () => {
	document.getElementById('loadText').classList.remove('hide');
	let searchInput = document.getElementById('search');
	let searchText = searchInput.value.toLowerCase();
	clearListItemSelection();

	if (searchText === 'themepicker') {
		createThemeBtn();
	}

	window.scrollTo(0, 0);
	document.getElementById('listings').scrollTo(0, 0);

	setTimeout(function () {
		buildList(searchText);

		if (searchText.length > 0) {
			document.getElementById('clearIcon').classList.remove('hideIcon');
		} else {
			document.getElementById('clearIcon').classList.add('hideIcon');
		}
	}, 50);
};

// Check if the content is scrollable to hide/show gradient mask in details
const updateGradient = () => {
	const scrollDiv = document.getElementsByClassName('selected')[0];
	const gradientDiv = scrollDiv ? scrollDiv.getElementsByClassName('scrollGradientMask')[0] : null;

	if (scrollDiv && gradientDiv) {
		if (scrollDiv.scrollHeight > scrollDiv.clientHeight) {
			gradientDiv.classList.add('show');
		} else {
			gradientDiv.classList.add('hide');
		}
	}
};

const orientationClass = () => {
	const horizontalOrientation = window.innerWidth > window.innerHeight;

	if (horizontalOrientation && window.mobileOrientation !== 'horizontal') {
		document.body.classList.add('horizontal');
		document.body.classList.remove('vertical');
		window.mobileOrientation = 'horizontal';
		setTimeout(() => {
			resetMapIfNoPath();
		});
	} else if (!horizontalOrientation && window.mobileOrientation !== 'vertical') {
		document.body.classList.add('vertical');
		document.body.classList.remove('horizontal');
		window.mobileOrientation = 'vertical';
		setTimeout(() => {
			resetMapIfNoPath();
		});
	}
}

let resizeTimeout;
window.addEventListener('resize', function () {
	clearTimeout(resizeTimeout);
	resizeTimeout = setTimeout(() => {
		window.replay();
		updateGradient();
		orientationClass();
		console.log('Window Resized');
	}, 200);
}, false);
