export function applyFilters(listings) {
    listings.forEach((listingItem, i) => {
        if (listingItem.listings.length > 0) {
            listings[i] = {...listings[i], listings: applyFilters(listings[i].listings)}
        }
    });
    
    if (!listings[0]?.filterOperations.add && !listings[0]?.filterOperations.remove) {
        return listings
    }

    if(listings[0].filterOperations.add) {
        let addedListings = applyAddFilters(listings)    

        if(listings[0].filterOperations.remove) {
            let removedListings = applyRemoveFilters(addedListings)
            return removedListings
        } else {
            return addedListings
        }
    } 

    if(listings[0].filterOperations.remove) {
        let removedListings = applyRemoveFilters(listings)
        return removedListings
    }
}

export function applyRemoveFilters(listings) {
    let listingsToBeRemoved = []
    
    if (listings.length > 0) {
        listings[0].filterGroup.forEach(group => {
            group.filters.forEach(filter => {
                if (filter.operation === ('(-)')) {
                    listings.forEach(listing => {
                        for (let [key1, value1] of Object.entries(listing.rawCols)){
                            if (key1 === group.colGroup) {
                                if (value1.toLowerCase().includes(filter.value.toLowerCase())) {
                                    listingsToBeRemoved.push(listing)
                                } 
                            }
                        }
                    });
                }
            });
        });
    }

    listings.forEach((listing, i) => {
        listingsToBeRemoved.forEach(flaggedListing => {
            if (listing.m1Key === flaggedListing.m1Key) {
                listings[i].mustRemove = true
            }
        });
    });

    let filteredListings = []

    listings.forEach(listing => {
        if (!listing.mustRemove) {
            filteredListings.push(listing)
        }
    });

    return filteredListings
}

export function applyAddFilters(listings) {
    let addedListings = []

    listings[0].filterGroup.forEach(group => {
        group.filters.forEach(filter => {
            if (filter.operation === ('(+)')) {
                listings.forEach(listing => {
                    for (let [key1, value1] of Object.entries(listing.rawCols)){
                        if (key1 === group.colGroup) {
                            if (value1.toLowerCase().includes(filter.value.toLowerCase())) {
                                let hasEqual = false
                                addedListings.forEach(addedListing => {
                                    if (addedListing.m1Key === listing.m1Key) {
                                        hasEqual = true
                                    }
                                });

                                if (!hasEqual) {
                                    addedListings.push(listing)
                                }
                            }
                        }
                    }
                });
            }
        });
    });

    return addedListings
}

export function buildFilterList(listing) {
    listing.forEach((listingItem, i) => {
        if (listingItem.listings.length > 0) {
            listing[i] = {...listing[i], listings: buildFilterList(listing[i].listings)}
        }

        let filterGroup = []
        let filterOperations = {add: false, remove: false}

        listingItem.colGroups.forEach(colGroup => {
            if (colGroup.filter) {
                filterGroup.push({filters: formatFilters(colGroup.filter), colGroup: colGroup.m1Key})
            }
        });

        filterGroup.forEach(group => {
            group.filters.forEach(filters => {
                if (filters.operation === '(+)') {
                    filterOperations.add = true
                }
                if (filters.operation === '(-)') {
                    filterOperations.remove = true
                }
            });
        });
        
        listing[i] = {
            ...listing[i], 
            filterGroup, 
            filterOperations 
        }
    });

    return listing
}

export function formatFilters(filters) {
    if (filters.length === 0) {
        return []
    }

    let finalArrayOfFilters = []
    
    filters.forEach(filter => {
        finalArrayOfFilters.push({
            operation: filter.slice(0,3),
            value: filter.slice(3) 
        })
    }); 

    return finalArrayOfFilters
}