import React from 'react';
import ReactDOM from 'react-dom/client';

import {
	WayfindingComponent,
} from 'wayfinding_lib';

import {
	clearListItemSelection
} from './mobile.js';

import { 
	envENV
} from './env';

import { getTLCUUID } from './mobile';

// Create Maps for better listing building
const setActiveMap = () => {
	const pendingUpdates = window.flatData;
	const activeSystem = `system.${window.systemUUID}`;

	let activeMap = '';
	let activeSystemPoint = '';
	let activeMapPointGroup = '';

	Object.entries(pendingUpdates).forEach(([m1Key, m1Data,]) => {
		if (m1Key.startsWith(activeSystem)) {
			Object.keys(m1Data).forEach((m2Key) => {
				if (m2Key.startsWith('wf_point.')) {
					activeSystemPoint = m2Key;
				}
			});
		}
	});
	
	Object.entries(pendingUpdates).forEach(([m1Key, m1Data,]) => {
		if (m1Key.startsWith('wf_point_group.')) {
			Object.keys(m1Data).forEach((m2Key) => {
				if (m2Key === activeSystemPoint) {
					activeMapPointGroup = m1Key;
				}
			});
		}
	});

	Object.entries(pendingUpdates).forEach(([m1Key, m1Data,]) => {
		if (m1Key.startsWith('wf_map_graphic.')) {
			Object.keys(m1Data).forEach((m2Key) => {
				if (m2Key === activeMapPointGroup) {
					activeMap = m1Key;
				}
			});
		}
	});

	window.activeMap = activeMap;
};

const getParentKeyNameFromTarget = (targetName, matchingValue) => {
	const flatData = window.flatData;
	let parentName = '';
	for (let m1KeyName of Object.keys(flatData)) {
		if (m1KeyName.startsWith(targetName)) {
			for (let m2KeyName of Object.keys(flatData[m1KeyName])) {
				if (m2KeyName === matchingValue) {
					parentName = m1KeyName;
					break;
				}
			}

			if (parentName) {
				break;
			}
		}
	}

	return parentName;
};

const getAccount = (fd = {}) => {
	if (!window.activeAccountM1Key) {
		let keys = Object.keys(fd);
		window.activeAccountM1Key = keys.find((key) => {
			return key.startsWith('account.');
		});
	}

	return fd[window.activeAccountM1Key] || {};
};

export const getSystemOrPropertyOrAccountSetting = (mapSetting, systemName = null, checkbox = false) => {
	const activeMap = window.activeMap;
	let settingValue = '';
	const pendingUpdates = window.flatData;

	const account = getAccount(pendingUpdates);
	const activeSystemGroup = pendingUpdates?.[getParentKeyNameFromTarget('system_group', activeMap)] || {};
	const activeSystem = pendingUpdates?.[systemName] || {};

	let accountSetting;
	if (mapSetting in account) {
		accountSetting = {
			value: account[mapSetting] ?? null,
		};
	}

	let propertySetting;
	if (mapSetting in activeSystemGroup) {
		propertySetting = {
			value: activeSystemGroup[mapSetting] ?? null,
		};
	}

	let systemSetting;
	if (mapSetting in activeSystem) {
		systemSetting = {
			value: activeSystem[mapSetting] ?? null,
		};
	}	

	if ((systemSetting?.value && !checkbox) || (checkbox && systemSetting?.value && systemSetting?.value !== false)) {
		settingValue = systemSetting.value;
	} else if ((propertySetting?.value && !checkbox) || (checkbox && propertySetting?.value && propertySetting?.value !== false)) {
		settingValue = propertySetting.value;
	} else if ((accountSetting?.value && !checkbox) || (checkbox && accountSetting?.value && accountSetting?.value !== false)) {
		settingValue = accountSetting.value;
	} else if (checkbox) {
		settingValue = false;
	} else {
		settingValue = '';
	}

	return settingValue;
};

export const wayfindingRef = React.createRef();

const getListingWayfindingData = (listingUUID) => {
	const flatData = window.flatData;

	let primaryWayfindingColumn = '';
	let secondaryWayfindingColumn = '';

	for (let [m1Key, m1Data] of Object.entries(flatData)) {
		if (m1Key.startsWith('listing_group.')) {
			for (let [m2Key, m2Data] of Object.entries(m1Data)) {
				if (m2Key.startsWith('listing_config.')) {
					if (m2Data.wf_column && flatData[listingUUID][`listing_column.${m2Data.wf_column}`]) {
						primaryWayfindingColumn = m2Data.wf_column;
					}
					if (m2Data.wf_fallback_column && flatData[listingUUID][`listing_column.${m2Data.wf_column}`]) {
						secondaryWayfindingColumn = m2Data.wf_fallback_column;
					}
				}
			}
		}
	}

	const landmarkIdentifier = flatData[listingUUID][`listing_column.${primaryWayfindingColumn}`]?.string;
	const landmarkIdentifierFallback = flatData[listingUUID][`listing_column.${secondaryWayfindingColumn}`]?.string;

	let legacyBuildingName = '';
	let legacyLandmarkId = '';
	let legacyLocationName = '';

	let listingConfigUUID = '';

	for (let [m1Key, m1Data] of Object.entries(flatData[listingUUID])) {
		if (m1Key.startsWith('listing_config.')) {
			listingConfigUUID = m1Key;
		}

		if (m1Key.startsWith('listing_column.')) {
			for (let [m2Key, ] of Object.entries(flatData[m1Key])) {
				if (m2Key.startsWith('listing_column_type.')) {
					if (flatData[m2Key].display_name === 'Building') {
						legacyBuildingName = m1Data.string;
					} else if (flatData[m2Key].display_name === 'Location (apt/suite)') {
						if (flatData[m1Key].display_name === 'landmark_id') {
							legacyLandmarkId = m1Data.string;
						} else {
							legacyLocationName = m1Data.string;
						}
					}
				}
			}
		}
	}

	return {
		legacyBuildingName,
		legacyLandmarkId,
		legacyLocationName,
		listingUUID,
		listingConfigUUID,
		landmarkIdentifier,
		landmarkIdentifierFallback,
		primaryWayfindingColumn,
		secondaryWayfindingColumn
	};
}

export const renderPath = (listingUUID) => {
	if (!wayfindingRef.current) {
		return;
	}

	wayfindingRef.current.setListingsAndGetLocation(getListingWayfindingData(listingUUID));
};

const matchingLocationsMobileUI = (matchingLocations) => {
	const selectedItem = document.querySelector('.selected');
	if (selectedItem) {
		const nearbyLocationsElem = selectedItem.querySelector('.nearbyLocations');
		const nearbyLocationsSetting = window?.newData?.mobileSettings?.show_nearby_locations || null;
		const matchingLocationsClass = nearbyLocationsSetting ? 'matchingLocations matchingLocationsShow' : 'matchingLocations';
		const matchingLocationsText = document.createElement('div');
		matchingLocationsText.className = 'matchingLocationsText';
		matchingLocationsText.innerHTML = 'Nearby Locations';
		
		if (Object.keys(matchingLocations).length > 0) {
			const container = document.createElement('div');
			container.className = matchingLocationsClass;
			container.appendChild(matchingLocationsText);
			
			Object.keys(matchingLocations).forEach((key, i) => {
				const locationDiv = document.createElement('div');
				locationDiv.className = 'matchingLocationBtn';
				locationDiv.setAttribute('data-listing', key);
				locationDiv.innerHTML = `${matchingLocations[key].name}`;
				locationDiv.dataset.location = matchingLocations[key].landmarkId;
				locationDiv.onclick = (e) => {
					clearListItemSelection();
					window.useNearbyLocation = key;
					const listingUUID = e.target.dataset.listing;
					const selectedListing = document.querySelector(`[data-name="${listingUUID}"]`);
					selectedListing.click();
				};
				container.appendChild(locationDiv);
			});
			
			if (nearbyLocationsElem) {
				nearbyLocationsElem.innerHTML = '';
				nearbyLocationsElem.appendChild(container);
			}
		} 
	}
}

export const resetMapIfNoPath = () => {
	if (wayfindingRef.current === null) {
		return;
	}

	wayfindingRef.current.resetMapIfNoPath();
	setTimeout(() => {
		wayfindingRef.current.reloadImage();
	});
};

export const resetMap = () => {
	if (wayfindingRef.current === null) {
		return;
	}

	wayfindingRef.current.resetToInitialMap();
};

export const clearWayfindingMessage = () => {
	if (wayfindingRef.current === null) {
		return;
	}

	wayfindingRef.current.clearMessage();
};

// used in html
window.replay = () => {
	if (wayfindingRef.current === null) {
		return;
	}

	wayfindingRef.current.reloadImage();
};

export const wayfindingMapStartup = (listingUUID = null) => {
	let landmarkIdentifier = null;
	let landmarkIdentifierFallback = null;
	let legacyBuildingName = null;
	let legacyLandmarkId = null;
	let legacyLocationName = null;
	let listingConfigUUID = null;

	if (listingUUID) {
		const listingWayfindingData = getListingWayfindingData(listingUUID);
		landmarkIdentifier = listingWayfindingData.landmarkIdentifier;
		landmarkIdentifierFallback = listingWayfindingData.landmarkIdentifierFallback;
		legacyBuildingName = listingWayfindingData.legacyBuildingName;
		legacyLandmarkId = listingWayfindingData.legacyLandmarkId;
		legacyLocationName = listingWayfindingData.legacyLocationName;
		listingConfigUUID = listingWayfindingData.listingConfigUUID;
	}

    setActiveMap();

    const systemData = window.flatData[`system.${window.systemUUID}`];
	let wayfindingProject = false;

	for (const m1Key of Object.keys(systemData)) {
		if (m1Key.startsWith('wf_point.')) {
			wayfindingProject = true;
		}
	}

	if (!wayfindingProject) {
        return false;
    }

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('hasWayfinding');

    const systemUUID = window.systemUUID;
    const flatData = window.flatData;
    const activeMap = window.activeMap;

    const url = new URL(window.location.href);
    const componentUUID = getTLCUUID();
    const env = url.searchParams.get('wf_env') || envENV;
    
    const activeSystemGroup = getParentKeyNameFromTarget('system_group', activeMap) || '';
    
    const locationMarkerUse = getSystemOrPropertyOrAccountSetting('wf_location_marker_use', `system.${systemUUID}`, true);
	window.locationMarkerUse = locationMarkerUse;
    const locationMarkerColor = getSystemOrPropertyOrAccountSetting('wf_location_marker_color', `system.${systemUUID}`, true);
    const locationMarkerSize = getSystemOrPropertyOrAccountSetting('wf_location_marker_size', `system.${systemUUID}`, true);
    const mapDelayIn = getSystemOrPropertyOrAccountSetting('wf_map_delay_in', `system.${systemUUID}`, true);
    const mapDelayOut = getSystemOrPropertyOrAccountSetting('wf_map_delay_out', `system.${systemUUID}`, true);
    const pathColor = getSystemOrPropertyOrAccountSetting('wf_path_color', `system.${systemUUID}`, true);
    const pathEndmarker = getSystemOrPropertyOrAccountSetting('wf_path_endmarker', `system.${systemUUID}`, true);
    const pathEndmarkerSize = getSystemOrPropertyOrAccountSetting('wf_path_endmarker_size', `system.${systemUUID}`, true);
    const pathWidth = getSystemOrPropertyOrAccountSetting('wf_path_width', `system.${systemUUID}`, true);
	const showLabels = window?.newData?.mobileSettings?.show_labels || null;
	const showNearbyLocations = window?.newData?.mobileSettings?.show_nearby_locations || null;
    
    const wayfindingMapContainerElement = document.getElementById('wayfindingMapContainer');
    
    const wayfindingRoot = ReactDOM.createRoot(wayfindingMapContainerElement);

    wayfindingRoot.render(
        <React.StrictMode>
            <WayfindingComponent
				componentUUID={componentUUID}
				css={{}}
				env={env}
				flatData={flatData}
				hideQRCode={true}
				isMobile={true}
				landmarkIdentifier={landmarkIdentifier}
				landmarkIdentifierFallback={landmarkIdentifierFallback}
				legacyBuildingName={legacyBuildingName}
				legacyLandmarkId={legacyLandmarkId}
				legacyLocationName={legacyLocationName}
				listingConfigUUID={listingConfigUUID}
				listingUUID={listingUUID}
				locationMarkerUse={locationMarkerUse}
				locationMarkerColor={locationMarkerColor}
				locationMarkerSize={locationMarkerSize}
				mapDelayIn={mapDelayIn}
				mapDelayOut={mapDelayOut}
				matchingLocationsMobileUI={matchingLocationsMobileUI}
				pathColor={pathColor}
				pathEndmarker={pathEndmarker}
				pathEndmarkerSize={pathEndmarkerSize}
				pathWidth={pathWidth}
				qrCodeText={''}
				ref={wayfindingRef}
				setToInitialMap={listingUUID ? false : true}
				showLabels={showLabels}
				showNearbyLocations={showNearbyLocations}
				systemUUID={systemUUID}
				systemGroupUUID={activeSystemGroup}
			/>
        </React.StrictMode>
    );

	let mapConfigUUID;
	
	for (const m1Key of Object.keys(flatData[activeMap])) {
		if (m1Key.startsWith('wf_config.')) {
			mapConfigUUID = m1Key;
		}
	}

	let mapContainerWidthUUID;
	let mapContainerHeightUUID;
	
	for (const [m1Key, m1Val] of Object.entries(flatData)) {
		if (m1Key.startsWith('setting.')) {
			if (m1Val.name ===  'map_container_width') {
				mapContainerWidthUUID = m1Key;
			}

			if (m1Val.name === 'map_container_height') {
				mapContainerHeightUUID = m1Key;
			}
		}
	}

	const mapContainerWidth = flatData[mapConfigUUID][mapContainerWidthUUID].string; 
	const mapContainerHeight = flatData[mapConfigUUID][mapContainerHeightUUID].string;

	const wayfindingModalElement = document.querySelector('#wayfindingModal');

	wayfindingModalResizeObserver = new ResizeObserver((entries) => {
		for (const entry of entries) {
			const {
				height: mapHeight,
				width: mapWidth,
			} = entry.contentRect;

			const scaleRatioX = mapWidth / mapContainerWidth;
			const scaleRatioY = mapHeight / mapContainerHeight;
			const useScale = Math.min(scaleRatioX, scaleRatioY) || 1;
			
			wayfindingMapContainerElement.style.transform = `scale(${useScale})`;
			wayfindingMapContainerElement.style.transformOrigin = 'top left';
		}
	});

	wayfindingModalResizeObserver.observe(wayfindingModalElement);

	return true;
};

let wayfindingModalResizeObserver = null;

// Wayfinding Map Zoom and Pan
const wayfindingMapContainerWrapper = document.getElementById('wayfindingMapContainerWrapper');
const zoomInBtn = document.getElementById('zoomIn');
const zoomOutBtn = document.getElementById('zoomOut');
const refreshBtn = document.getElementById('refreshBtn');

const zoomStep = 0.1;
let translateX = 0; // Initial X position for panning
let translateY = 0; // Initial Y position for panning
let isDragging = false; // Track if the user is dragging
let startX, startY; // To store initial position when dragging (mouse or touch)
let initialDistance = 0; // For pinch zoom

function getCurrentZoom() {
	const computedStyle = window.getComputedStyle(wayfindingMapContainerWrapper);
	let transformValue = computedStyle.getPropertyValue('transform');

	if (transformValue === 'none') {
		return 1;
	}

	return parseFloat(transformValue.match(/matrix\(([^,]*)/)[1]);
}

function updateTransform(mapZoom, translateX, translateY) {
	// Set the transform origin to the center
	wayfindingMapContainerWrapper.style.transformOrigin = 'center center';

	// Apply both translation and scaling
	wayfindingMapContainerWrapper.style.transform = `translate(${translateX}px, ${translateY}px) scale(${mapZoom})`;
}

// Zoom event listeners
zoomInBtn.addEventListener('click', () => {
	let mapZoom = getCurrentZoom();
	mapZoom = Math.min(mapZoom + zoomStep, 3);
	updateTransform(mapZoom, translateX, translateY, true);
	wayfindingRef.current.setZoom(mapZoom);
});

zoomOutBtn.addEventListener('click', () => {
	let mapZoom = getCurrentZoom();
	mapZoom = Math.max(mapZoom - zoomStep, 0.2);
	updateTransform(mapZoom, translateX, translateY, true);
	wayfindingRef.current.setZoom(mapZoom);
});

// Add a listener to the refresh button that will reset the zoom and pan
refreshBtn.addEventListener('click', () => {
	translateX = 0;
	translateY = 0;
	updateTransform(1, 0, 0);
	const mapZoom = getCurrentZoom();
	wayfindingRef.current.setZoom(mapZoom);
});

// Mouse events for dragging the image
wayfindingMapContainerWrapper.addEventListener('mousedown', (event) => {
	isDragging = true;
	startX = event.clientX - translateX; // Capture starting mouse X position
	startY = event.clientY - translateY; // Capture starting mouse Y position
	wayfindingMapContainerWrapper.style.cursor = 'grabbing';
	document.body.classList.add('noselect');
});

// Touch events for dragging and pinch zoom on touch devices
wayfindingMapContainerWrapper.addEventListener('touchstart', (event) => {
	if (event.touches.length === 1) {
		// Single touch: Start dragging
		isDragging = true;
		startX = event.touches[0].clientX - translateX;
		startY = event.touches[0].clientY - translateY;
	} else if (event.touches.length === 2) {
		// Two-finger touch: Start pinch zoom
		isDragging = false;
		initialDistance = getDistance(event.touches);
	}
	document.body.classList.add('noselect');
});

const getDistance = (touches) => {
	const [touch1, touch2] = touches;
	const dx = touch2.clientX - touch1.clientX;
	const dy = touch2.clientY - touch1.clientY;
	return Math.sqrt(dx * dx + dy * dy);
};

window.addEventListener('touchmove', (event) => {
	let mapZoom = getCurrentZoom();
	if (event.touches.length === 1 && isDragging) {
		// Dragging with one finger
		translateX = event.touches[0].clientX - startX;
		translateY = event.touches[0].clientY - startY;
		updateTransform(mapZoom, translateX, translateY);
	} else if (event.touches.length === 2) {
		// Pinch zoom with two fingers
		const newDistance = getDistance(event.touches);
		const zoomFactor = newDistance / initialDistance;
		mapZoom = Math.max(0.5, Math.min(3, zoomFactor)); // Limit zoom between 0.5x and 3x
		updateTransform(mapZoom, translateX, translateY);
	}
});

window.addEventListener('touchend', () => {
	isDragging = false;
	document.body.classList.remove('noselect');
});

// Listen for orientation changes
window.addEventListener('orientationchange', function () {
	window.scrollTo(0, 0);
	document.getElementById('listings').scrollTo(0, 0);
}, false);

window.addEventListener('mousemove', (event) => {
	if (!isDragging) return;

	translateX = event.clientX - startX;
	translateY = event.clientY - startY;

	updateTransform(getCurrentZoom(), translateX, translateY);
});

window.addEventListener('mouseup', () => {
	if (isDragging) {
		isDragging = false;
		wayfindingMapContainerWrapper.style.cursor = 'grab';
		document.body.classList.remove('noselect');
	}
});

document.addEventListener('getsturestart', (e) => { e.preventDefault(); });
document.addEventListener('getsturechange', (e) => { e.preventDefault(); });
document.addEventListener('getstureend', (e) => { e.preventDefault(); });